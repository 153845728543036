import './App.css';
import logo from './neurocoin_logo.png';
import React, { useState } from 'react';

function App() {
  const [showPopup, setShowPopup] = useState(true);

  const closePopup = () => {
    setShowPopup(false);
  };

  const navigateToUrl = (url) => {
    window.location.href = url;
  };

  return (
    <div className="App">
      {showPopup && (
        <div className="popup">
          <p>IMPORTANT: Neurocoin is still in development and the Chrome Extension is our first and currently sole offering.</p>
          <button className="close-popup-button" onClick={closePopup}>X</button>
        </div>
      )}
      <header className="App-header">
        <nav>
          <div className="logo-container">
            <img src={logo} alt="Neurocoin Logo" className="logo-image" />
            <span className="logo-text">Neurocoin</span>
          </div>
          <button className="download-button">Extension Setup</button>
        </nav>
        <div className="main-content">
          <h1>Get paid for your productivity.</h1>
          <p>Neurocoin rewards you for focusing on your work, not using your phone, and building positive habits. Refer to extension setup after downloading.</p>
          <div className='center-screen'>
            <div className="button-container">
              <a className="clean-button" href={`${process.env.PUBLIC_URL}/neurocoin-chrome-3.zip`} download="neurocoin_extension.zip">Download Chrome Extension</a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="info-blocks">
          <div className="info-block">
            <h2>Earn</h2>
            <p>Use our Chrome Extension to track your productivity and earn Neurocoin!</p>
          </div>
          <div className="info-block">
            <h2>Withdraw</h2>
            <p>Easily withdraw your earned Neurocoins to receive a special discount code.</p>
          </div>
          <div className="info-block">
            <h2>Redeem</h2>
            <p>Use your discount code in the shop to redeem various rewards for half price compared to Amazon!</p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
